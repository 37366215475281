import React from "react";
import Layout from "../../components/Layout/Layout";
import SEO from "../../components/Seo";
import { Box, Button, Container, Image, T2 } from "../../styles/Styles";
import iconCite from "../../img/icon-quote.png";
import Mikan from "mikanjs";
// benefits videos
import videoCloud from "../../img/cloud-visibility.mp4";
import videoRoute from "../../img/hc2-layers.mp4";
// benefits posters
import posterCloud from "../../img/cloud-visibility-poster.png";
import posterRoute from "../../img/hc2-layers-cover.jpg";
import posterOperations from "../../img/reduce-flight-delay-cover.png";

import hero from "../../img/hero-drones-ind.png"
import Clients from "../../components/Homepage/Clients";

const benefits = [
  {
    webm: null,
    mp4: videoCloud,
    poster: posterCloud,
    title: "安全を確保",
    caption: `関心のある天候状況を確認：可視性、雲量など`,
    text: `雲クラウドベースの正確な高度情報で、規制に沿った距離を保つのは大切です。資産を保護し、天候が起因の事故による損失を最小限に抑えましょう。`,
  },
  {
    webm: null,
    mp4: videoRoute,
    poster: posterRoute,
    title: "ルート計画を改善",
    caption: "あなたのロケーションへ、雨が移動してくるか追跡します",
    text: "飛行計画に沿った、非常に正確な予報を活用することで、最も安全なミッションルートを計画し、飛行時間を最大化できます。",
  },
  {
    webm: null,
    mp4: null,
    poster: posterOperations,
    title: `オペレーションの<br/>効率化を向上`,
    caption: null,
    text: "最適な飛行タイミングを知り、不必要な警戒を減少させ、より多くのミッションを完結して、収益を増やすことができます。",
  },
];

export default class DronesIndustry extends React.Component {
  state = {};

  render() {
    return (
      <Layout>
        <SEO
          title="ドローン | tomorrow.io"
          image={hero}
          pathname={this.props.location.pathname}
        />

        <Container>
          <Box display="flex" flexWrap="wrap" pb={4}>
            <Box
              width={[1, 1 / 2]}
              pr={[0, 4]}
              pt={5}
            >
              <Box mb={3} fontSize={17} dangerouslySetInnerHTML={{__html: Mikan('ドローンのミッションを計画するとき、天候は予測不能な要素ですか？')}}/>
              <T2 as="h1" mb={4}>天候に驚かされることを<br/>やめましょう</T2>

              <Button
                as="a"
                display={["block", "inline-block"]}
                mb={2}
                href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
              >
                tomorrow.ioデモ取得
              </Button>{" "}
              <Button
                outline="1"
                as="a"
                display={["block", "inline-block"]}
                mb={2}
                href="https://www.tomorrow.io/api-signup/"
              >
                APIキー取得
              </Button>
            </Box>
            <Box width={[1, 1 / 2]} pt={2} px={3} textAlign="center">
              <Image
                maxHeight="380px"
                src={hero}
                alt=""
              />
            </Box>
          </Box>
        </Container>

        <section className="c-section py-5">
          <div className="container">
            <Clients/>
          </div>
        </section>

        <section className="c-section ">
          <div className="container">

              <div className="col-lg-9 mx-auto text-center px-2">
                <h2 className="h-2-sm mb-5" dangerouslySetInnerHTML={{__html: Mikan('毎年、飛行機会を失い、資産を損傷する結果となるのは、財政的にも顧客満足度においても顕著に影響します。')}}/>
                <p className="fs-lg" dangerouslySetInnerHTML={{__html: Mikan('従来の気象予報をする企業は、ほとんどの場合、衛星や気象レーダーに頼っている為、飛行に関連のある400フィート以下の気象状況を見逃しています。tomorrow.ioの地上レベル、分単位、非常に正確な予報、ドローンの操縦士は雨、雪、雷、風速、究極極端な気温などにも対応し、オペレーションと安全性を向上できます。')}}/>
              </div>

            {benefits.map((item, i) => (
              <div key={i} className="row justify-content-center py-4 py-lg-6">

                  <div
                    className={`col-lg-4 pr-lg-4 ${
                      i % 2 === 0 ? "order-lg-last" : ""
                    }`}
                  >
                    <h3 className="h-3" dangerouslySetInnerHTML={{__html: item.title}}/>
                    <p className="fs-lg">{item.text}</p>
                  </div>

                <div className="col-lg-7">

                    <div className="embed-responsive embed-responsive-16by9">
                      <video
                        className="embed-responsive-item shadow"
                        poster={item.poster}
                        width="800"
                        height="480"
                        controls={false}
                        muted
                        playsInline
                        autoPlay
                        loop
                      >
                        {item.webm && (
                          <source src={item.webm} type="video/webm" />
                        )}
                        {item.mp4 && <source src={item.mp4} type="video/mp4" />}
                        } Your browser does not support the video tag
                      </video>
                    </div>
                    <div className="text-center px-4 py-2">{item.caption}</div>

                </div>
              </div>
            ))}
            <div className="text-center pt-6">

                <a
                  className="btn btn-lg btn-primary h-3 "
                  href="https://www.tomorrow.io/get-a-demo-of-hypercast/"
                >
                  デモを予約
                </a>

            </div>
          </div>
        </section>

        <section className="c-section pt-5 ">

            <div className="col-lg-6 mx-auto text-center">
              <img className="mb-3" src={iconCite} alt="" />
              <p
                className="fs-lg font-italic mb-4"
                style={{ fontFamily: "Georgia, serif" }}
                dangerouslySetInnerHTML={{__html: Mikan('UASidekickの目標は、飛行中の操縦士の安全を確保すること。tomorrow.ioのMicro Weatherソリューションのような、次世代ツールを提供することはUAS産業を前進させるために欠かせないことです。このレベルくらいにで詳細な天候を理解することは、オペレーターが生産性を最大限にすると同時に、地上の設備や作業員への責任やリスクを最小限にするといった、戦略的なフライト計画を立てることができる、新しい将来性です。')}}
              />
              <p className="mb-3">ナタン・ラフ氏、 UASidekick CEO</p>
              <div className="text-center">
                <a
                  className="btn btn-lg btn-primary h-3 "
                  href="https://www.tomorrow.io/ebooks/drones-ebook/"
                >
                  もっと読む
                </a>
              </div>
            </div>

        </section>

      </Layout>
    );
  }
}
